//alert(JSON.stringify(dataApi,null,2))

//este formulario, me muestra una simple tabela
//Contiene TODAS las cuotas de UN plan de UN alumno (osea UNA matricula de plan)
//Me muestra todas las cxc sin importar si ya está cancelada o no. Es análogo a un estado de cuenta
//Si desde mi Api recibo NULL entonces me regreso al llamador

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,Input,Label} from 'reactstrap'
import DataTable  from 'react-data-table-component'
import {devolverFechaHoyTextoYYYYMMDD,ejecutarFetchGenericoConSwal,mostrarSwalConfirmacionEliminarAnular,mostrarSwalBotonAceptar,mostrarSwalPos } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//modales
import ModificarUnaCuotaDeUnPlan  from './ModificarUnaCuotaDeUnPlan'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let conQueAccionDeboRegresar='close' //puede ser 'close' 
let dataApi=null //lo que recibo de mi Api (Null,lista de cxc)
let fechaDeHoy=null //lo uso solo cuando se desea INCLUIR una cuota manual (y se lo mando como parametro al form ModificarUnaCuotaDeUnPlan)

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE xxxxxxxx'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'moccasin',
      //fontSize:'22px',   
      //fontWeight: 'bold',
		},
	},   
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'moccasin', //ok
      color: 'gray',  //ok lavender. magenta
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
        minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },    
    cells: {
      style: {
        fontSize:'16px', //16px
        //el borde solo lo quiero ABAJO
        // top | right | bottom | left 
        //border-style: none solid dotted dashed;      
        borderStyle:"none none solid none", 
        borderColor:'silver',
        borderWidth:'thin',    
        //marginLeft:'20px', //funciona
        //paddingLeft: '80px', // no me funciona override the cell padding for data cells
        //paddingRight: '8px',
        //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
        //backgroundColor:'yellow', //
        //fontWeight:'bold',
        //height:'10px', /* bacan */
        //paddingTop:'0', /* no funciona */
        //paddingBottom:'0', /* no funciona */
        //margin:0, /* no me funciona */
      },
    },
}

//por fin. Aquí no es necesario recibir como parametro la variable row (NO SE POR QUE ? hay que averiguar )\
//la variable row parece que viene IMPLICITA
const filaCondicionalExterna=(rowRegistroState)=>[
{
    when: (row) => (rowRegistroState!=null && row.ClaveConcat==rowRegistroState.ClaveConcat),
    style: (row) =>({ 
      backgroundColor:valueDC?.sistema?.coloresFilaSeleccionadaPrincipal,
      color: 'white',
     }),    
},  
]

const VerCxCdeUnPlanDeUnAlumno=(props)=>{  
  const [rowRegistroState, set_rowRegistroState] = useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState] = useState('lista') //Arranco viendo obviamente la lista de la tabela
  const [registrosFullStateCxC, set_registrosFullStateCxC] = useState([]) //OBLIGATORIO EMPEZAR con []. Sirve para guardar TODOS los registros (en el use effect si recibe NULL se devuelve al componente llamador)
  const [modalIncluirCuota, set_modalIncluirCuota] = useState(false) 
  const [modalModificarCuota, set_modalModificarCuota] = useState(false)   

  const eliminarJsonRegistro=(ClaveConcat_tmp)=>{
    //elimino de la tabela, UNA cuota.
    let datosFullCopia=registrosFullStateCxC.filter(item=>item.ClaveConcat != ClaveConcat_tmp)
    //actualizo el estado para la tabela, y quito la franja azul
    set_registrosFullStateCxC(datosFullCopia) 
    set_rowRegistroState(null)
    rowRegistroClon=null
  }

  const refrescarData=async()=>{
    dataApi=null
    let data=new FormData()
    data.append('miSol','matriculapp_buscarCxCdeUnPlanDeUnAlumno') //me trae las cxc de UN alumno de UN plan. Me trae todas las filas sin importa si están canceladas o no
    data.append('codAlu',props.IDr_alu) //rojo del alumno
    data.append('idPlan',props.IDplan) //codigo rojo del Plan
   
    //al recibir NULL, entonces éste form se cierra automaticamente
    dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
    set_registrosFullStateCxC(dataApi) 
    //console.log('=======cxc vacan=====')
    //console.log(dataApi)
  }   

  const hayFilaSeleccionada=()=>{
    //Solo valida que realmente haya UNA fila seleccionada
    let miDevolver=true
    if (rowRegistroClon==null){
      mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Cuota")          
      miDevolver=false
    }
    return miDevolver
  }

  const tieneAbono=()=>{
    //Me sirve para saber si la cuota YA tiene abono(s)
    let miDevolver=false
    if (rowRegistroClon.Abonos_cxc != "0.00"){
      mostrarSwalBotonAceptar("error","ATENCION","No se puede avanzar, la cuota tiene abonos")          
      miDevolver=true
    }
    return miDevolver
  }

// ==============================================================
// ========= simulo el componentDidMount y ciclo de vida ========
//===============================================================
useEffect(()=>{
  rowRegistroClon=null
  //Al arrancar muestro las cxc del man
  refrescarData()

  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    set_registrosFullStateCxC([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null)
    rowRegistroClon=null
  }
  },[]
)
  // *** cuando recibo NULL de mi API, entonces me devuelvo al componente llamador
  useEffect(()=>{  
  if (registrosFullStateCxC==null){
    props.ocultarModal_VerCxC(conQueAccionDeboRegresar) 
  }  
  },[registrosFullStateCxC]  
)
// =====================================================
// ============== fin de ciclo de vida =================
//======================================================

const miEstructura=[
{
  name: 'IDcuota_cxc',  
  selector: row => row.IDcuota_cxc,
  sortable: false,
  center: true,
  grow: 0.2,
  compact: true,
  omit: true, //oculta (siempre) la celda
},
{
  name: 'ClaveConcat',  
  selector: row => row.ClaveConcat,
  sortable: false,
  center: true,
  grow: 0.2,
  compact: true,
  omit: true, //oculta (siempre) la celda
},
{
  name: 'DESCRIPCION',
  selector: row => row.Descripcion_cxc,
  sortable: false,
  compact: true,
  grow: 3, //es una medida relativa (1 es mi referencia)
},
{
  name: 'VENCE',
  selector: row => row.FechaVence_cxc,
  sortable: false,
  compact: true,
  center:true,
  grow: 1,  
  omit:false,
},
{
  name:'MONTO',
  selector:row => row.MontoCuota_cxc,
  sortable:false,
  compact:true,
  center:true,
  grow:1,
  //width:'20%',
  hide:'sm',  
}, 
{
  name:'INT',
  selector:row => row.Interes_cxc,
  sortable:false,
  compact:true,
  center:true,
  grow:0.5,
  //hide:'sm',  
  omit: true, 
}, 
{
  name:'ABONOS',
  selector:row => row.Abonos_cxc,
  sortable:false,
  compact:true,
  center:true,
  grow:1,
  //width:'20%',
  hide:'sm',  
}, 
{
  name:'SALDO',
  selector:row => row.Saldo_cxc,
  sortable:false,
  compact:true,
  center:true,
  grow:1,
  //width:'20%',
  hide:'sm',  
}, 
{
  name:'TIPO',
  selector:row => row.TipoPM_cxc,
  sortable:false,
  compact:true,
  center:true,
  grow:1,
  hide:'sm',  
}, 
]

const eliminarCuotaEnBDD=async()=>{
  //elimino fisicamente de la BDD, UNA cuota (afecta a la tabla cxc_alumnos)
  let data=new FormData()
  data.append('miSol','matriculapp_eliminarUnaCxC')
	data.append('codAlumno',rowRegistroClon.CodAlumno_cxc)
  data.append('tipoPM',rowRegistroClon.TipoPM_cxc)
  data.append('codCuota',rowRegistroClon.IDcuota_cxc)
  
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)  
  return dataApi //dominio: null,-100,0,1 (lo ideal es 1)
}

const ComponentePrincipalBody = () => {
return (
  <div id="divMaster" name="divMaster" style={{ width: '100%', minHeight: '80vh', background: 'lightcyan' }}>
    {/**** barra de titulo  */}
      <div id="divBarraTitulo" name="divBarraTitulo">
        <Row style={{ margin: '0' }}>
          <Col xs="10">
            <Label style={{ marginTop: '5px', fontWeight: 'bold', color: "pink" }}>{ `PLAN: ${props.NombrePlan} `}</Label>
          </Col>
          <Col xs="2" style={{ textAlign: 'right', padding: '0' }}>
            <Button style={{ width: '40px', height: '40px', }} id="btnCerrar" name="btnCerrar" color="danger"
              onClick={() => {
                props.ocultarModal_VerCxC(conQueAccionDeboRegresar)                        
              }}>
              <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
            </Button>
          </Col>
        </Row>
      </div> {/* divBarraTitulo */}

      {/* ***** TABELA ***** */}
      <div id="divTabela" name="divTabela" style={{ marginTop: '2%', marginLeft: '1%', marginRight: '1%', borderStyle: "solid", borderColor: 'black', borderWidth: '2px', background: 'purple', width: '98%', marginBottom: '15px', }} >
        <DataTable
          //************ DATA TABLA PROPERTIES (basic) ***********/
          title={ `${props.RazonSocial_alu} / ${props.IdPrincipal_alu}` }
          columns={miEstructura}
          data={ registrosFullStateCxC ?? [] } 
          //conditionalRowStyles={filaCondicionalInterna} //Asi también funciona pefecto
          conditionalRowStyles={filaCondicionalExterna(rowRegistroState)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
          keyField='ClaveConcat'  
          //este onRowClicked, me sirve solamente para que al tocar/clickear un fila, se ponga de fondo morado u otro color                  
          onRowClicked={(row) => {
            set_rowRegistroState(row)
            rowRegistroClon = row
          }}                  
          //striped  //NO PONER, Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
          highlightOnHover //SI, poner
          //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
          noDataComponent=""
          //************ DATA TABLA PROPERTIES (pagination) ***********/
          pagination
          //paginationServer //change de default pagination to work with server pagination
          paginationPerPage={15} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
          paginationComponentOptions={paginacionOpciones}

          //************ DATA TABLA PROPERTIES (header) ***********/
          //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
          //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
          customStyles={miEstilacho} /* redefino algun estilo */ 
          />{/* del componente DataTable */}                   
      </div> {/* divTabela */}

      {/* botonera inferior */}
      <div id="divMenuInf" name="divMenuInf" 
      style={{backgroundColor:'silver',paddingTop:'1%', paddingBottom:"1%",display : props.modificar_cxc_SN == "N" ? 'none ' : 'block'}} >
        <Row style={{margin:'0',}}>
          <Col xs="4" style={{margin:'0',padding:'0',textAlign:'center'}}>
            <Button id="btnNuevaCuota" name="btnNuevaCuota" color="secondary" solid="true" style={{width:"80%", marginRight:'3%',color:'pink'}} 
              onClick={async()=>{  
                fechaDeHoy=devolverFechaHoyTextoYYYYMMDD()
                set_modalIncluirCuota(true)
              }}>NUEVA CUOTA
            </Button>
          </Col>
          <Col xs="4" style={{margin:'0',padding:'0',textAlign:'center'}}>  
            <Button id="btnModificarCuota" name="btnModificarCuota" color="secondary" solid="true"  style={{width:"80%",color:'pink'}} 
              onClick= { async() => {                
                if ( !hayFilaSeleccionada() ) return
                if ( tieneAbono() ) return              
                set_modalModificarCuota(true)
              }}>MODIFICAR
            </Button>
          </Col> 
          <Col xs="4" style={{margin:'0',padding:'0',textAlign:'center'}}>  
            <Button id="btnEliminarCuota" name="btnEliminarCuota" color="secondary" solid="true"  style={{width:"80%",color:'pink'}} 
              onClick= { async() => {
                if ( !hayFilaSeleccionada() ) return
                if ( tieneAbono() ) return   

                //Pregunto si realmente quiere eliminar 
                const miMensaje="Seguro desea eliminar la cuota: \n" + rowRegistroClon.Descripcion_cxc
                const miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miMensaje)
                if (miResp.isDismissed) return //alomejor se arrepintio
        
                //Guardo la clave, para luego eliminarla de la tabela
                let ClaveConcat_tmp=rowRegistroClon.ClaveConcat

                //Elimino Fisicamente
                let definitivo=await eliminarCuotaEnBDD() //En definitivo puedo recibir: null,-100,0,1 (lo ideal es 1)
                if (definitivo==null) return
                if (definitivo<0){
                  await mostrarSwalBotonAceptar("warning","NO SE PUDO ELIMINAR","Hubo un error en la BDD")
                  return 
                }
                if (definitivo==0){
                  await mostrarSwalBotonAceptar("warning","NO SE PUDO ELIMINAR","La cuota tiene abonos")
                  return 
                }
                //Al llegar aquí, es porque realmente se pudo eliminar.
                //pongo una notificacion abajo a la derecha
                mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
                eliminarJsonRegistro(ClaveConcat_tmp) //elimino del json y refresco la tabela                                 
              }}>ELIMINAR
            </Button>
          </Col>            
        </Row>             
      </div> {/* divMenuInf */}

      {/* ************************* MODAL PARA INCLUIR UNA CUOTA *************** */}
      <Modal style={{ backgroundColor: 'blue', }} size={'md'} isOpen={modalIncluirCuota}>
        <ModificarUnaCuotaDeUnPlan
          ocultarModal_Modificar={ (accion, jsonSerie) => {
            set_modalIncluirCuota(false)
            //accion puede ser: close/save
            if (accion=='close') return
            //muestro todas cuotas (la insertada + las hermanas). Así me ahorro recargar leyendo de la BDD
            set_registrosFullStateCxC(jsonSerie) 
            //debo simular un clic sobre el registro insertado. Tomando en cuenta solo las del tipo M, busco el codigo mas alto   
            let IDcuota_cxc_tmp=0 //valor de arranque
            jsonSerie.forEach( fila => {
              if (fila.TipoPM_cxc=="M" && fila.IDcuota_cxc>IDcuota_cxc_tmp){
                IDcuota_cxc_tmp=fila.IDcuota_cxc
                set_rowRegistroState(fila)  
                rowRegistroClon=fila
              }
            })

            }
          }
          //le mano variables que tengo en el clon   
          titulo={"NUEVA CUOTA MANUAL "} //titulo para la barra de titulo
          subTitulo={"NUEVA"} //Subtitulo que se pone en la pestana del tab
          tablaQueAfecta={"cxc_alumnos"} //podria ser: planpagos_detalle / cxc_alumnos
          opcionRegistro_imc={"i"} //desde esta tabela, puede ser "m" / "i"
          codAlumno={props.IDr_alu} //viene del form GestionarPlanesDeUnAlumno
          idPlan={props.IDplan} //viene del form GestionarPlanesDeUnAlumno
          monto={""}
          fecha={ fechaDeHoy }
          descripcion={""}                  
        />
      </Modal>            

      {/* ************************* MODAL PARA MODIFICAR UNA CUOTA *************** */}
      <Modal style={{ backgroundColor: 'blue', }} size={'md'} isOpen={modalModificarCuota}>
        <ModificarUnaCuotaDeUnPlan
          ocultarModal_Modificar={(accion, jsonSerie) => {
            set_modalModificarCuota(false)
            //accion puede ser: close/save
            if (accion=='close') return
            //save
            if (accion=='save') {
              //muestro todas cuotas (la editada+las hermanas). Así me ahorro recargar leyendo de la BDD
              set_registrosFullStateCxC(jsonSerie) 
              //debo simular un clic sobre el registro afectado.
              let claveConcatOriginal=rowRegistroClon.ClaveConcat
              jsonSerie.forEach( fila => {
                if (fila.ClaveConcat==claveConcatOriginal){
                set_rowRegistroState(fila)  
                rowRegistroClon=fila
                }
              })
            }
          } }
          //le mano variables que tengo en el clon   
          titulo={"CUOTA=> " + rowRegistroClon?.Descripcion_cxc} //titulo para la barra de titulo
          subTitulo={"MODIFICAR CUOTA"} //Subtitulo que se pone en la pestana del tab
          tablaQueAfecta={"cxc_alumnos"} //podria ser: planpagos_detalle / cxc_alumnos
          opcionRegistro_imc={"m"} //desde esta tabela, puede ser "m" / "i"
          codAlumno={rowRegistroClon?.CodAlumno_cxc}
          tipoPM={rowRegistroClon?.TipoPM_cxc}
          codCuota={rowRegistroClon?.IDcuota_cxc}
          monto={rowRegistroClon?.MontoCuota_cxc}
          fecha={rowRegistroClon?.FechaVence_cxc}
          descripcion={rowRegistroClon?.Descripcion_cxc}                  
        />
      </Modal>      

  </div> //divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState == 'lista') return ComponentePrincipal()

} // del const ModificarPlanDePagos_Detalle

export default VerCxCdeUnPlanDeUnAlumno

