//alert(JSON.stringify(jsonNegra,null,2))
import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,ButtonGroup, Input, Form} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, { sleepPepe,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal } from './lib_basica'
import {ejecutarFetchGenericoConSwal} from './lib_basica'
import {mostrarTresOpcionesConSwal,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLightbulb,faPencilAlt,faGlasses,faRegistered,faRunning,faCamera,faDoorOpen,faBroom,faBookReader,faBookOpen, faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed, faSearchDollar } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)let jsonPeriodos=null //Necesito los periodos para llenar un COMBO, al momento de crear un Plan o Modificar su cabecera. Cuando queda null, significa que realmente hubo errore de conexion o error mas fisico en BDD
let dataApi=null //Aqui recibo el campo DATA que me devuelve mi API

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'LISTA DE PLANES'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      //paddingLeft: '50px', //estaba '50px', no me funciona, override the cell padding for head cells
      //paddingRight: '8px', //estaba '8px'
      background: 'hotpink', //ok hotpink
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    }, 
  },  
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',       
    }
  },    
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL
      //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */      
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: row => (row.IDplan != filaClonada?.IDplan ),
    style: row => ({ 
      //backgroundColor:row.ActivoPlan=="1"  ? 'white' : valueDC.sistema.coloresItemInactivo,
      backgroundColor:'white',
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (row.IDplan == filaClonada?.IDplan),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

const TabelaPlanesDePagoInactivos=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //OBLIGATORIO EMPEZAR con []. Sirve para guardar TODOS los registros (en el use effect si recibe NULL se devueove al MenuPrincipal)
  const [rowRegistroState,set_rowRegistroState]=useState(null) //(UN REGISTRO). mas que todo para color de la fila seleccionada y la tabela
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
  const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
  const [modalIncluirPlan,set_modalIncluirPlan]=useState(false) //para abrir el modal de incluir NUEVO plan
  const [modalModificarCabeceraPlan,set_modalModificarCabeceraPlan]=useState(false) //para abrir el modal de modificar el plan (pero solo la cabecera) 
  const [modalModificarDetallePlan,set_modalModificarDetallePlan]=useState(false) //para abrir el modal de modificar el plan (pero solo el detalle) 
  const [modalPractica,set_modalPractica]=useState(false)  

const miEstructura=[  
  {  
      name:'ID',
      selector:row => row.IDplan,      
      sortable:true,
      center:true,
      grow:0.2,
      compact:true, //padding 0
      //width:'50%',
      omit:true, //oculta (siempre) la celda
  },
  /*
  {
      name:'PRINCIPAL',
      selector:row => row.IdPrincipal,      
      sortable:true,
      compact:true,
      grow:1, //es una medida relativa (1 es mi referencia)
      //width:'13%',
      //style: {backgroundColor:'cyan'},
  },
  */
  {
      name:'NOMBRE DEL PLAN',
      selector:row => row.NombrePlan,
      backgroundColor:'red',   //solo es para probar (loego eliminar esta fila)    
      sortable:true,
      compact:true,
      grow:1.5,
  },       
  {
    //ACCIONES
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1, //originalmente 1.6    
    //width:'20%',

    //esto funciona fino
    //cell: (row) => <div><div style={{ fontWeight: 'bold' }}>{row.IDr}</div>{row.IdPrincipal}</div>,
    //esto funciona fino
    //cell: (row) => <div style={{ fontWeight: 900 }}>{row.IDr}</div>,
    //<div style={{ display:'flex' }}>            
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton ACTIVAR rosa */}
    <Button id="btnActivar" name="btnActivar" style={{ marginRight:'1px',color:'dimgray'}} color="info" 
      onClick={ async (e) => {         
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        if (!hayFilaSeleccionada()) return

        //pregunto si realmente desea avanzar
        const miRegistroEnviar="Seguro desea convertir en Activo al plan: \n" + rowRegistroClon.NombrePlan
        const miResp = await mostrarSwalConfirmacionEliminarAnular('ATENCION',miRegistroEnviar)
        if (miResp.isDismissed) return //alomejor se arrepintio

        let definitivo=await convertirEnActivoEnBDD(rowRegistroClon.IDplan) //En definitivo puedo recibir: null,numero positivo (osea el mismo enviado)
        if (definitivo==null) return
        //pongo una notificacion abajo a la derecha
        mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
        eliminarDeTabela(rowRegistroClon.IDplan) //elimino del json y refresco la tabela     
        }}>
        Activar
    </Button>
          
    {/* dropdown para el boton MAS... */}
    {/* al hacer clic por segunda vez consecutiva se debe apagar el desplegable */}
{/* no se usa DROP MAS en éste form
    <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.IDplan  } direction="right"  
      toggle= { ()=> {
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.IDplan : 0)
        }}>    
      <DropdownToggle color="info">
        <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
      </DropdownToggle>

      <DropdownMenu style={{backgroundColor:'pink',}} >

        <DropdownItem onClick={ async() => {
          if (!hayFilaSeleccionada()) return
          //pregunto si realmente desea avanzar
          const miRegistroEnviar="Seguro desea convertir en inactivo al plan: \n" + rowRegistroClon.NombrePlan
          const miResp = await mostrarSwalConfirmacionEliminarAnular('ATENCION',miRegistroEnviar)
          if (miResp.isDismissed) return //alomejor se arrepintio

          let definitivo=await convertirEnInactivoEnBDD(rowRegistroClon.IDplan) //En definitivo puedo recibir: null,numero positivo (osea el mismo enviado)
          if (definitivo==null) return
          //pongo una notificacion abajo a la derecha
          mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
          eliminarDeTabela(rowRegistroClon.IDplan) //elimino del json y refresco la tabela     
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faEye} /> Convertir en Inactivo
        </DropdownItem>    
         
        <DropdownItem divider />
        <DropdownItem><FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>

      </Dropdown>
        */}        
    </div>, 
  },   
  /* 
  {
    name:'COD_PERIODO',
    selector:row => row.IDaliasPeriodo,
    sortable:true,
    compact:true,
    grow:1,
    //width:'20%',
    hide:'sm',  
  }, 
  */
  {
    name:'PERIODO',
    selector:row => "(" + row.IDaliasPeriodo + ") "+ row.NombrePeriodo,
    sortable:true,
    compact:true,
    grow:1.5,
    //width:'20%',
    hide:'sm',  
  }, 
  {
    name:'GRAVA',
    selector:row => row.GravaIvaPlan,
    sortable:true,
    compact:true,
    center:true,    
    grow:0.8,
    omit:true,
  },      
  {
    name:'CREA',
    selector:row => row.OpCreaPlan,
    sortable:true,
    compact:true,
    grow:0.5,
    hide:'sm',
  },    
  {
    name:'MOD',
    selector:row => row.OpModPlan,
    sortable:true,
    compact:true,
    grow:1.5,
    hide:'sm',  
    omit:true, //el campo OpModPlan NUNCA se usa (solo OpCreaPlan)
  },    
]

const modificarJsonTabela=(miJson)=>{
  //Lo que hago es Modificar una fila en especial (segun los cambios que hizo el usario al plan cabecera)  
  //Los pisibles cambios serían (6 campos): IDperiodoPlan,NombrePlan,GravaIvaPlan,IDperiodo,IDaliasPeriodo,NombrePeriodo
  //Es posible que no quede actualizada los datos del director del posible cambio de periodo pero no importa
  //cuando es un NUEVO plan, entonces hay que leer de nuevo desde la BDD

  let filaEditada=null //guardo la fila editada, solo para poder simular un clic sobre ella
  let datosFullCopia=null
  datosFullCopia=registrosFullState.map( (item)=>{
    if (item.IDplan!=miJson.IDplan) return item
    if (item.IDplan==miJson.IDplan) {
      //modifico pocos campos del item (los otros campos quedan igual)
      item.IDperiodoPlan=miJson.IDperiodoPlan
      item.NombrePlan=miJson.NombrePlan
      item.GravaIvaPlan=miJson.GravaIvaPlan
      item.IDperiodo=miJson.IDperiodo
      item.IDaliasPeriodo=miJson.IDaliasPeriodo
      item.NombrePeriodo=miJson.NombrePeriodo
      filaEditada=item
      return item
    }
  })

  set_registrosFullState(datosFullCopia)    
  //quería simular un clic sobre la fila editada pero la pantalla se congela en edicion :( 
  //set_rowRegistroState(filaEditada)  
  //rowRegistroClon=filaEditada
}

const convertirEnActivoEnBDD=async(IDplan)=>{
  let data=new FormData()
  data.append('miSol','planPago_ponerActivoInactivoPlanDePago')
  data.append('codPlan',IDplan)
  data.append('activoPlan',1) //le mando 0 (inactivo)

  //puede ser null, o el mismo rojo enviado (lo ideal)
  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  return miRecibido
}
  
const hayFilaSeleccionada=()=>{
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un Plan")          
    miDevolver=false
  }
  return miDevolver
}

const refrescarData2023=async()=>{
  let data=new FormData()
  data.append('miSol','planPago_buscarPlanesDePagoConPeriodos')
  data.append('orderBy','NombrePlan')
  data.append('forma','Asc')
  data.append('selectAIT','I') //Quiero solo los activos (tanto para Planes como Periodos)
  
  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullState(miRecibido)   
}

// ==============================================================
// ========= simulo el componentDidMount y ciclo de vida ========
//===============================================================
useEffect(()=>{
  refrescarData2023()
  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //variables normales
    rowRegistroClon=null
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al menu principal
useEffect(()=>{  
  if (registrosFullState==null){    
    props.activarMenu() 
  }
  },[registrosFullState]
)

// =====================================================
// ============== fin de ciclo de vida =================
//======================================================

const filtrarElementos=()=>{
  set_rowRegistroState(null)                 
  rowRegistroClon=null
  set_idMasState(0)

  let cadenaLoca=document.getElementById("inputBusqueda").value.trim().toLocaleLowerCase()  
  let filtradosTmp=registrosFullState.map(item=>{
    if (
      item.NombrePlan.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IDaliasPeriodo.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombrePeriodo.toString().toLocaleLowerCase().includes(cadenaLoca)
      )  
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item      
  })
  set_registrosFullState(filtradosTmp)
}  

const eliminarPlanEnBDD=async(codPlanEli)=>{
  //Elimina fisicamente el Plan y su detalle (afecta 2 tablas)
  //Luego de eliminar de la BDD, entonces elimino del JSON de la tabela también
  dataApi=null  

  let data=new FormData()
  data.append('miSol','planPago_eliminarUnPlanDePagos')
  data.append('codPlan',codPlanEli)
  
  //Puedo recibir null,-100,numero positivo (ideal)
  //-100 significa que ese plan ya fue usado en matriculas y no se pudo eliminar
  dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  if (dataApi===null) return
  if (dataApi<0) return 

  //Rumbo Ideal (Ahora elimino de la tabela)
  eliminarDeTabela(codPlanEli)
}

const eliminarDeTabela=async(codPlanEli)=>{
  let datosFullCopia=registrosFullState.filter(item=>item.IDplan != codPlanEli)
  //actualizo el estado para la tabela, y quito la franja azul
  set_registrosFullState(datosFullCopia)
  set_rowRegistroState(null)
  rowRegistroClon=null
}

const ComponentePrincipalBody=()=>{  
return(      
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 
   <div id="divSubMaster" name="divSubMaster">
   {/******* Boton superior de OPCIONES (utilidades) ************/}
   <Row style={{margin:'0',paddingLeft:'0px'}} >     
   <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidadesState }  size="md"
    toggle= {()=>set_dropUtilidadesState(!dropUtilidadesState)}>
      <DropdownToggle caret color="primary">
        Opciones
      </DropdownToggle>
  
      <DropdownMenu style={{backgroundColor:'cyan',}} >
        <DropdownItem onClick={ () => alert('Listado' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faGlasses} /> Listado</DropdownItem>        
        <DropdownItem onClick={ () => alert(JSON.stringify(registrosFullState,null,2) )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de datos full</DropdownItem>                
        <DropdownItem onClick={ () => {
            alert(JSON.stringify(rowRegistroState,null,2))
            alert(JSON.stringify(rowRegistroClon,null,2))
          }}
          ><FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de la fila seleccionada
        </DropdownItem>            
        <DropdownItem divider />
        <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>
    </Dropdown>
    </Row>

    {/******* Menu superior, para telefono vertical: solo 4 botones ************  */}
    <div id="divMenuSuperior" name="divMenuSuperior" > 
      <Row style={{margin:'0px',}} >
        <Col>
          <ButtonGroup>    
            <Button id="btnBloc" name="btnBloc" className="btnPpal btnBordebtnPpal"   
              onClick = { () => { alert('Bloc') } } >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faBookOpen} />
              </span>
              <span><br/>Bloc</span>
            </Button>        
            <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
              onClick={async()=>{       
                if (await hayInternet()==false) return
                set_rowRegistroState(null)
                rowRegistroClon=null
                set_idMasState(0)
                document.getElementById('inputBusqueda').value=''
                await refrescarData2023()
              }}>  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /> 
              </span>
              <span><br/>Refresh</span>          
            </Button>
            <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"   
              onClick = { () => { 
                props.activarMenu()
              }} >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /> 
              </span>
              <span><br/>Salir</span>          
            </Button>
          </ButtonGroup>
        </Col>    
      </Row>
    </div> {/* divMenuSuperior */}

    {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
        <Input
          className = "textoBusquedaClientes"
          type="text" placeholder="Texto de búsqueda" id="inputBusqueda" name="inputBusqueda" autoComplete='off'        
          onChange={ ()=>filtrarElementos() }
          />
        <Button  
          id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
          onClick={()=>{
            document.getElementById('inputBusqueda').value=''
            filtrarElementos()
          }}> 
          <FontAwesomeIcon color="paleGreen" icon={faBroom}/>
        </Button>        
    </div> { /* divBuscador1 */ }

   </div> { /* divSubMaster */ }

    { /* inicio del DIV para la tabela */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title='Lista de Planes Inactivos'
    columns={miEstructura}
    data={  registrosFullState==null ? [] : registrosFullState.filter(item=>item.VisibleSN=='S')}
    conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
    keyField ='IDplan' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
    onRowClicked={ (row) => {       
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_idMasState(0)      
    }}
    //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""
    //    <h1><span>Nada por aquí</span></h1>
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //'60vh'  
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras

    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //falta: rvesiar esta parte, me parece interesante ya que debe arrancar ordenando por DESCRIPCION
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    //paginationPerPage={10} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationPerPage={ valueDC.sistema.paginadoParaPlanes }  
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

</div>  //Del divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} //const TabelaPlanesPago

export default TabelaPlanesDePagoInactivos
