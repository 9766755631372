//alert(JSON.stringify(jsonNegra,null,2))
//para mostrar en JS la fecha actual, con let f=new Date() me devuelve: Tue Nov 02 2021 20:32:22 GMT-0500 (hora de Ecuador)
//NOTA IMPORTANTE: todos los Row, deben tener ajuro margin:'0' listo ok
import React, {useState,useEffect} from 'react'
import {Label,Row,Col,Button,Input,Dropdown,DropdownItem, DropdownMenu,DropdownToggle,Modal} from 'reactstrap'
import buscarPhpPath, {devolverFechaHoyTextoYYYYMMDD,buscarHostnameSegunJs,consultarDatosBasicosDeLaFirmaElectronicaConSwal,mostrarSwalBotonAceptar,generarReporteLibroVentasPDFconSwal,generarReporteFacturasAnuladasPDFconSwal,generarReporteLibroVentasNotasEntregaPDFconSwal,hayInternet, procesarCompraEnBDD,sleepPepe } from './lib_basica'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPercent,faFilePowerpoint, faBook, faObjectGroup,faBox, faTh, faFileInvoiceDollar,faObjectUngroup, faMap,faBoxes,faCartPlus,faFileSignature, faNewspaper,faFileInvoice ,faPowerOff, faRunning,faUsers,faUserFriends,faUserAlt,faUser,faUserPlus,faUserAltSlash,faLightbulb,faCartArrowDown, faShoppingCart, faCubes,faBookReader,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faTools, faStepBackward, faDollar } from '@fortawesome/free-solid-svg-icons' 
//componentes a llamar desde el menu principal
import TabelaEstudiantes from './TabelaEstudiantes'
import TabelaEstudiantesInactivos from './TabelaEstudiantesInactivos'
import TabelaTerceros from './TabelaTerceros'
import TabelaClientesMorosos  from './TabelaClientesMorosos'
import TabelaPlanesDePago from './TabelaPlanesDePago'
import TabelaPlanesDePagoInactivos from './TabelaPlanesDePagoInactivos'
import TabelaFacturasLista from './TabelaFacturasLista'
import TabelaInteres from './TabelaInteres'
import MostrarDatosBasicosFirmaElectronica from './MostrarDatosBasicosFirmaElectronica'
import EncriptarTextoEnJS from './EncriptarTextoEnJS'
import EncriptarTextoEnPHP from './EncriptarTextoEnPHP'
import VerContext from './VerContext'
import VerPdfOk from './VerPdfOk'
//*****pantallas negra para reportes***
import PantNegraLibroVentas from './PantNegraLibroVentas'
import PantNegraPedirFechaIniFechaFin from './PantNegraPedirFechaIniFechaFin'
//del context
import {AppContextConsumer} from './ContextBase'
import { type } from '@testing-library/user-event/dist/type'
import PedirFechaDeCorte from './PedirFechaDeCorte'
//Variables normales
let valueDC=null //Aqui hago una copia de los valores del context  
let pepeJsonConsultarFirma=null //aqui guardo los datos de la firma
let pdfCreadoCorrectamenteSN=null //para generar algun pdf
let diaTransito=null // para pasar de una pantalla a otra (fecha)
let mesTransito=null // para pasar de una pantalla a otra (fecha)
let anoTransito=null // para pasar de una pantalla a otra (fecha)

const MenuPrincipalBonanza=(props)=>{
  const [nombreComponenteParaVer,setNombreComponenteParaVer]=useState('menu') //Nombre del componente que deseo ver. Arranca obviamente con el menu principal
  const [modalTabelaInteres,set_modalTabelaInteres]=useState(false)  
  const [modalDatosFirma,set_modalDatosFirma]=useState(false)   
  const [modalEncriptarEnJS,set_modalEncriptarEnJS]=useState(false)     
  const [modalEncriptarEnPHP,set_modalEncriptarEnPHP]=useState(false)       
  const [modalVerContext,set_modalVerContext]=useState(false)         
  const [modalPedirFechaCorte,set_modalPedirFechaCorte]=useState(false)         
  const [modalLibroVentasNegra,set_modalLibroVentasNegra]=useState(false)         
  const [modalLibroVentasPDF,set_modalLibroVentasPDF]=useState(false)           
  const [modalFacturasAnuladasNegra,set_modalFacturasAnuladasNegra]=useState(false) 
  const [modalFacturasAnuladasPDF,set_modalFacturasAnuladasPDF]=useState(false)                       
  const [dropUtilidades,set_dropUtilidades]=useState(false)   
  const [dropInactivos,set_dropInactivos]=useState(false)    //Para el menú de los elementos inactivos
  //para las credenciales superiores de mi cliente. Frontera >=880
  const [licenciadoLargo,set_licenciadoLargo]=useState('')   
  const [licenciadoCorto,set_licenciadoCorto]=useState('')
  const [estabMasPuntoLargo,set_estabMasPuntoLargo]=useState('') //Estab:001 - Punto:999
  const [estabMasPuntoCorto,set_estabMasPuntoCorto]=useState('') //001-999   

const gestionarConsultarDatosBasicosDeLaFirmaElectronica=async()=>{
  //primero reviso que tenga activada la opcion de usar la firma
  if(valueDC.licencia.FirmaElectronicaUsar=="0" || !valueDC.licencia.FirmaElectronicaUsar ){
    await mostrarSwalBotonAceptar("error","ERROR","No está habilitada la opción de Firma Electrónica")
    return
  }

  pepeJsonConsultarFirma=await consultarDatosBasicosDeLaFirmaElectronicaConSwal(valueDC) 
  //primero hay que revisar que pepeJsonConsultarFirma.AgrModConExito este true. 
  if (pepeJsonConsultarFirma.AgrModConExito==false) return
  
  await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
  set_modalDatosFirma(true)
}

const despedirseSonido=()=>{
  //hay que revisar si tiene preferencias de sonido
  if (valueDC.licencia.SonidosVoz=="0") return

  speechSynthesis.speak(new SpeechSynthesisUtterance(valueDC.licencia.DespedidaHablada))
}

const buscarHostnameSegunPhp=async()=>{
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','host_buscarHostnameSegunPhp')
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeText = await response.text() 

    alert(pepeText)
  }
  catch{
  }
}

//detectar el nombre de la BDD en produccion (la idea es que sea el mismo del subdominio)
const detectarNombreDeBDD=async()=>{
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','host_detectarNombreDeBDD')
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeText = await response.text() 

    alert(pepeText)
  }
  catch{
  }
}

const buscarPathDeLaAplicacion=async()=>{
  //document.location.hostname //me devuelve (localhost para local) o (www.sistemaspaladino.com para remoto) **** OK ***
  alert("document.location.hostname=> " + document.location.hostname)
  alert("document.domain=> " + document.domain) //devuelve localhost
  alert("document.URL=> " + document.URL)   //devuelve http://localhost:3000/
}

const verAmbiente=async()=>{
  if (valueDC.licencia.AmbienteActualPRUPRO=="PRU")
    alert("Prueba")
  if (valueDC.licencia.AmbienteActualPRUPRO=="PRO")
    alert("Produccion")      
}

const activarMenu=() => {
  setNombreComponenteParaVer('menu') 
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //Licencia del man
  set_licenciadoLargo("Licencia: " + valueDC.licencia.Ruc + " / " + valueDC.licencia.RazonSocial)   
  set_licenciadoCorto("Licencia: " + valueDC.licencia.Ruc)   
  //estab y punto del usuario
  set_estabMasPuntoLargo("Estab: " + valueDC.usuario.EstabUsu + "- Punto: " + valueDC.usuario.PuntoUsu )
  set_estabMasPuntoCorto(valueDC.usuario.EstabUsu + "-" + valueDC.usuario.PuntoUsu)

  //reviso que se esté usando la ultima version del front. (al ser diferente entonces forzo un reload)
  if (valueDC.licencia.FrontVersion != props.frontVersion){
    //alert('diferente')
    console.log('recargando....')
    window.location.reload()
  }
},[]
)

const mostrarCredencialesSup=()=>{
  return(
  <div style={{width:'100%', display:'flex'}} >
    <div id="divAncho" name="divAncho" style={{width: 'calc(100% - 50px)', height:'44px',backgroundColor:'black'}} >
      <div id="divVersion" name="divVersion" style={{height:'23px',backgroundColor:'purple'}}>        
        <Row style={{margin:'0'}}>
            <Col xs="8" style={{paddingTop:'0px',margin:'0px'}}>
              <Label id="labelVersion" name="labelVersion" style={{color:'pink',fontSize:'13px'}} >Paladino-Bonanza V.5.00</Label>
            </Col>
            <Col xs="4" style={{paddingTop:'0px',margin:'0px',textAlign:'right'}}>
              <Label id="labelUsuario" name="labelUsuario" style={{color:'pink',fontSize:'13px',}} >{valueDC.usuario.LoginUsu}</Label>
            </Col>  
        </Row>  
      </div>
      {/*peachpuff, 'papayawhip' */}
      <div id="divLicencia" name="divLicencia" style={{height:'23px',backgroundColor:'hotpink'}}>        
        <Row style={{margin:'0'}}>
            <Col xs="7" md="9" style={{paddingTop:'0px',margin:'0px',textAlign:'left',}}>
              <Label id="licenciadoLargo" name="licenciadoLargo" className='classLicenciadoLargo' style={{color:'purple',fontSize:'13px',fontWeight: 'regular'}} >
                {licenciadoLargo}
              </Label>
              <Label id="licenciadoCorto" name="licenciadoCorto" className='classLicenciadoCorto' style={{color:'purple',fontSize:'13px',fontWeight: 'regular'}} >
                {licenciadoCorto}
              </Label>
            </Col>
            <Col xs="5" md="3" style={{paddingTop:'0px',margin:'0px',textAlign:'right',}}>
              <Label id="estabMasPuntoLargo" name="estabMasPuntoLargo" className='classLicenciadoLargo' style={{color:'purple',fontSize:'13px',}} >
                {estabMasPuntoLargo}
              </Label>
              <Label id="estabMasPuntoCorto" name="estabMasPuntoCorto" className='classLicenciadoCorto' style={{color:'purple',fontSize:'13px',}} >
                {estabMasPuntoCorto}
              </Label>            
            </Col>  
        </Row>  
      </div>  
    </div>
    {/* className="BotonMenuPpalSalir" */}
    <div id="divBoton" style={{width:'50px', height:'46px',backgroundColor:'gray',textAlign:'center',lineHeight:'42px'}} >
      <Button id="btnSalir" name="btnSalir" style={{width:'40px',height:'40px',backgroundColor:'black'}}
        onClick = { () => { 
          gestionarSalir()
        }}>  
        <span>
          <FontAwesomeIcon color="white" icon={faPowerOff} /> 
        </span>      
      </Button>
    </div>
  </div>  
  )
  }
  
  //en las credenciales inferiores, solo pongo el nombre comercial
  // slateblue,aqua,Moccasin
  const mostrarCredencialesInf=()=>{
  return(
    <div id="divFooter" name="divFooter" className="classFooterMenuPpal classFooterMenuPpalVisibilidad" style={{background:'purple'}}>
      <Row style={{margin:'0'}}>
        <Col xs="12" >
          <Label id="labelComercial" name="labelComercial" style={{color:'white',fontSize:'12px', fontFamily:'fantasy,monospace,arial,', fontWeight: 'regular',}} >
            {valueDC.estable.NombreComercial}
          </Label>
        </Col>
      </Row>  
    </div>
  )}

  const gestionarSalir=()=>{
    //borro la sessionStore
    sessionStorage.removeItem("usuario")
    //mando un sonido que diga hasta luego o algo parecido.
    despedirseSonido()
    props.regresarAlLogin()  
  }

const ComponentePrincipalBody=()=> {   
return (
<div id="divMaster" name="divMaster" className="classContainerMenuPrincipal">
  {mostrarCredencialesSup()}
  <div id="divSubMaster" name="divSubMaster"> 
    {/******* Boton superior de OPCIONES (utilidades) ************/}
    <Row style={{margin:'0',paddingLeft:'10px', }} >   
      <Col style={{display:'flex' }} >
        <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }} id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidades }  size="md"
        toggle= { ()=> {
          set_dropUtilidades(!dropUtilidades) 
        }}>
        <DropdownToggle caret color="primary">
          Opciones
        </DropdownToggle>
        <DropdownMenu style={{backgroundColor:'cyan',}} >
          <DropdownItem onClick={ () => set_modalLibroVentasNegra(true) }>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Libro de ventas
          </DropdownItem>         
          <DropdownItem onClick={ () => { set_modalFacturasAnuladasNegra(true) }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Facturas anuladas</DropdownItem>      
          <DropdownItem divider />                     

          <DropdownItem onClick={ () => alert( buscarHostnameSegunJs() )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> HostName segun JS</DropdownItem>        
          <DropdownItem onClick={ async() => { 
            if (await hayInternet()==false) return
            buscarHostnameSegunPhp() 
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> HostName segun PHP</DropdownItem>         
          <DropdownItem onClick={ () => buscarPathDeLaAplicacion() }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> PATH de la aplicacion</DropdownItem>                
          <DropdownItem onClick={ async() => {
            if (await hayInternet()==false) return
            detectarNombreDeBDD() 
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Nombre de la BDD en hosting</DropdownItem>         
          <DropdownItem onClick={ async() => {
              await gestionarConsultarDatosBasicosDeLaFirmaElectronica() 
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Consultar datos de la firma electrónica</DropdownItem>
          <DropdownItem onClick={ () => set_modalEncriptarEnJS(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Encriptar en JS</DropdownItem>        
          <DropdownItem onClick={ () => set_modalEncriptarEnPHP(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Encriptar en PHP</DropdownItem>        
          <DropdownItem onClick={ () => set_modalVerContext(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Ver Context</DropdownItem>        
          <DropdownItem onClick={ () => verAmbiente() }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Ver Ambiente</DropdownItem>
          <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
        </DropdownMenu>
        </Dropdown>

        <Dropdown style = {{marginTop:'10px',marginBottom:'10px', marginLeft:'10px' }} id="dropInactivos" name="dropInactivos" isOpen={ dropInactivos }  size="md"
          toggle= { ()=> {
            set_dropInactivos(!dropInactivos) 
          }}>
          <DropdownToggle caret color="primary">
            Inactivos
          </DropdownToggle>
          <DropdownMenu style={{backgroundColor:'cyan',}} >
            <DropdownItem onClick={ () => setNombreComponenteParaVer('estudiantesinactivos')  }>
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Estudiantes Inactivos
            </DropdownItem>         
            <DropdownItem onClick={ () => setNombreComponenteParaVer('planesinactivos')  }>
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Planes Inactivos
            </DropdownItem>   
            <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>                                              
          </DropdownMenu>
        </Dropdown>      
      </Col>
    </Row>
    <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnPlanes" name="btnPlanes" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
          onClick = { async() => { 
            setNombreComponenteParaVer('planes') 
            }}>  
          <span className="TamanoIconoMenuPpal2024">
            <FontAwesomeIcon color="paleGreen" icon={faLightbulb} />
          </span>
          <span><br/>PLANES</span>
        </Button>
      </Col>
      <Col style={{textAlign:'right'}}>
        <Button id="btnTerceros" name="btnTerceros"  className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
          onClick = { async() => { 
            setNombreComponenteParaVer('terceros') 
            }}>  
          <span className="TamanoIconoMenuPpal2024">
            <FontAwesomeIcon color="paleGreen" icon={faUsers}/> 
          </span>
          <span><br/>TERCEROS</span>
        </Button>
      </Col>      
    </Row>
    <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnMorosos" name="btnMorosos"  className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
          onClick = { async() => { 
            set_modalPedirFechaCorte(true)
          }}>  
          <span className="TamanoIconoMenuPpal2024">
            <FontAwesomeIcon color="paleGreen" icon={faDollar}/> 
          </span>
          <span><br/>MOROSOS</span>
        </Button>
      </Col>
      <Col style={{textAlign:'right'}}>
        <Button id="btnEstudiantes" name="btnEstudiantes" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
          onClick = { async() => {
            setNombreComponenteParaVer('estudiantes') 
          }} >  
          <span className="TamanoIconoMenuPpal2024">
          <FontAwesomeIcon color="paleGreen" icon={faUsers}/> 
          </span>
          <span><br/>ESTUDIANTES</span>
        </Button>
      </Col>      
    </Row>
    <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnConfig" name="btnConfig" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
          onClick = { async() => {  
            //alert(1.00=="1.00")// true
            //alert(1.00=="1.0") // true
            //alert(1=="1.00") //true
             //nada
          }}>  
          <span className="TamanoIconoMenuPpal2024">
            <FontAwesomeIcon color="paleGreen" icon={faTools}/> 
          </span>
          <span><br/>AJUSTES</span>
        </Button>     
      </Col>
      <Col style={{textAlign:'right'}}>
        <Button id="btnFacturas" name="btnFacturas" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
          onClick = { async() => {
            if (await hayInternet()==false) return
            setNombreComponenteParaVer('lista de facturas') 
            } } >  
          <span className="TamanoIconoMenuPpal2024">
            <FontAwesomeIcon color="paleGreen" icon={faMap} />
          </span>
          <span><br/>FACTURAS</span>
        </Button>
      </Col>      
    </Row>    
    <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>

      </Col>      
      <Col style={{textAlign:'right'}}>
 
      </Col>      
    </Row>

  </div>  {/* divSubMaster */}

  {/************ MODAL PARA VER LOS DATOS DE LA FIRMA ELECTRONICA *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalDatosFirma } >
    <MostrarDatosBasicosFirmaElectronica
      cerrarModal={()=> {
        set_modalDatosFirma(false)
      }}
      pepeJson={ pepeJsonConsultarFirma }      
    />
  </Modal>   

  {/* *********** MODAL PARA VER LA TABELA DE INTERES ************ */}
  <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalTabelaInteres } >
    <TabelaInteres
      cerrarModal={()=> {
        set_modalTabelaInteres(false)
      }}  
    />
  </Modal>     

  {/************ MODAL PARA ENCRIPTAR EN JS *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalEncriptarEnJS } >
    <EncriptarTextoEnJS
      cerrarModal={()=> {
        set_modalEncriptarEnJS(false)
      }}
    />
  </Modal>   

  {/************ MODAL PARA ENCRIPTAR EN PHP *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalEncriptarEnPHP } >
    <EncriptarTextoEnPHP
      cerrarModal={()=> {
        set_modalEncriptarEnPHP(false)
      }}
    />
  </Modal>

  {/************ MODAL PARA VER EL CONTEXT *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalVerContext } >
    <VerContext
      cerrarModal={()=> {
        set_modalVerContext(false)
    }}
    />
  </Modal>   

  {/************ MODAL PARA PEDIR FECHA DE CORTE *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalPedirFechaCorte } >
    <PedirFechaDeCorte
      ocultarModal_Fecha={(accion,fechaObjeto)=> {
        set_modalPedirFechaCorte(false)
        //accion puede ser: close/save
        if (accion=='close') return
        //la fecha la recibo como Objeto
        diaTransito=fechaObjeto.getDate()
        mesTransito=fechaObjeto.getMonth()+1
        anoTransito=fechaObjeto.getFullYear()
        setNombreComponenteParaVer('morosos') 
      }}
      Titulo={'Estudiantes Morosos'} //va sin titulo
      TituloTab={'Fecha de corte'}
    />
  </Modal>    

  {/************ MODAL PARA PEDIR PARAMETROS PARA LIBRO DE VENTAS DE FACTURAS *************/}
  <Modal style={{ backgroundColor:'black',}} size={'md'}  isOpen={ modalLibroVentasNegra } >
    <PantNegraLibroVentas
      miTitulo={"Libro de ventas"}
      botonExcelSN={"N"}
      cerrarModal={async(seguir,jsonNegra,botonEP)=> {
        set_modalLibroVentasNegra(false)
        if (!seguir) return
        //alert(JSON.stringify(jsonNegra))

        if (botonEP=="P"){
          //debo generar el PDF y luego lo muestro  
          pdfCreadoCorrectamenteSN=await generarReporteLibroVentasPDFconSwal(valueDC,jsonNegra)
          if (pdfCreadoCorrectamenteSN=="S"){
            set_modalLibroVentasPDF(true)
          }
        }
        if (botonEP=="E"){
          //debo generar el EXCEL  y luego supongo se descarga al pc del cliente
          //alert('excel')
        }            
    }}
    />
  </Modal>     

  {/************ MODAL PARA PEDIR PARAMETROS PARA FACTURAS ANULADAS *************/}
  <Modal style={{ backgroundColor:'black',}} size={'md'}  isOpen={ modalFacturasAnuladasNegra } >
    <PantNegraPedirFechaIniFechaFin
      miTitulo={"Facturas Anuladas"}
      botonExcelSN={"N"}
      cerrarModal={async(seguir,jsonNegra,botonEP)=> {
        set_modalFacturasAnuladasNegra(false)
        if (!seguir) return
        //alert(JSON.stringify(jsonNegra))

        if (botonEP=="P"){
          //debo generar el PDF y luego lo muestro  
          pdfCreadoCorrectamenteSN=await generarReporteFacturasAnuladasPDFconSwal(valueDC,jsonNegra)
          if (pdfCreadoCorrectamenteSN=="S"){
            set_modalFacturasAnuladasPDF(true)
          }                   
        }
        if (botonEP=="E"){
          //debo generar el EXCEL  y luego supongo se descarga al pc del cliente
          alert('excel')
        }            
      }}
    />
  </Modal>    

  {/* *********** MODAL PARA VER EL LIBRO DE VENTAS ************ */}
  <Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ modalLibroVentasPDF } >
    <VerPdfOk
      documentoTitulo={"Libro de ventas"}
      documentoArchivo={"LIBROventas_" + valueDC.usuario.LoginUsu }
      corchetesSN={"N"} //NO quiero ver entre corchetes el nombre del archivo
      tipoSriSN={"N"} //es un documento del sri?
      estiloPantallaPG={"P"} //Para modal le mando P
      activarMenu={ ()=> set_modalLibroVentasPDF(false) } 
    />
  </Modal>   

  {/* *********** MODAL PARA VER LAS FACTURAS ANULADAS ************ */}
  <Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ modalFacturasAnuladasPDF } >
    <VerPdfOk
      documentoTitulo={"Facturas Anuladas"}
      documentoArchivo={"FACTURASanuladas_" + valueDC.usuario.LoginUsu }
      corchetesSN={"N"} //NO quiero ver entre corchetes el nombre del archivo
      tipoSriSN={"N"} //es un documento del sri?
      estiloPantallaPG={"P"} //Para modal le mando P
      activarMenu={ ()=> set_modalFacturasAnuladasPDF(false) } 
    />
  </Modal>  
  {mostrarCredencialesInf()}   
</div> //divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
  if (nombreComponenteParaVer==='menu') return ComponentePrincipal()
  if (nombreComponenteParaVer==='terceros') return (<TabelaTerceros activarMenu={ activarMenu } />)  
  if (nombreComponenteParaVer==='estudiantes') return (<TabelaEstudiantes activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='estudiantesinactivos') return (<TabelaEstudiantesInactivos activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='planes')  return (<TabelaPlanesDePago activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='planesinactivos')  return (<TabelaPlanesDePagoInactivos activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='lista de facturas') return (<TabelaFacturasLista activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='morosos') return (
  <TabelaClientesMorosos 
    dia={diaTransito}
    mes={mesTransito}
    ano={anoTransito}
    activarMenu={ activarMenu } 
  />)
    
  } // const MenuPrincipalBonanza

export default MenuPrincipalBonanza