//alert(JSON.stringify(jsonNegra,null,2))

//este formulario, me permite editar/modificar el detalle de un PLAN (nunca su cabecera)
//el llamador, me envia en las props para el encabezado:=>
//opcionRegistro_imc,IDplan,NombrePlan,GravaIvaPlan
//También viene un parametro para saber si el Plan es nuevo o uno viejo. 
//*** cuando ya el plan existía peviamente
//Este form puede ser llamado desde la tabela cuando deseo modificar un detalle de plan que ya existía previamente
//la ruta sería: tabela=>éste modal
// ===== ojo: cuando éste modal es llamado desde NUEVO plan
//Pero éste modal tambien puede ser llamado desde otro modal (osea cuando estoy creando por primera vez el plan)
//en ese caso la ruta sería tabela=>modal nuevo plan => éste modal
//en definitiva en props.opcionRegistro_imc tiene que indicarme si el detalle es para un plan recien hecho('i'). O para uno ya viejo 'm'

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,Input,Label} from 'reactstrap'
import DataTable  from 'react-data-table-component'
import {hayInternet,ejecutarFetchGenericoConSwal,mostrarSwalConfirmacionEliminarAnular,mostrarSwalBotonAceptar,mostrarSwalPos } from './lib_basica'
import PedirSerieDePagos from './GenerarSerieDePagos'
import ModificarUnaCuotaDeUnPlan  from './ModificarUnaCuotaDeUnPlan'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let conQueAccionDeboRegresar='close' //puede ser 'close' o 'save'
let opcionRegistro_imc='' //para saber si deseo modificar-eliminar la cuota

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE xxxxxxxx'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
		},
	},   
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
        minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },    
    cells: {
      style: {
        fontSize:'16px', //16px
        //el borde solo lo quiero ABAJO
        // top | right | bottom | left 
        //border-style: none solid dotted dashed;      
        borderStyle:"none none solid none", 
        borderColor:'silver',
        borderWidth:'thin',    
        //marginLeft:'20px', //funciona
        //paddingLeft: '80px', // no me funciona override the cell padding for data cells
        //paddingRight: '8px',
        //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
        //backgroundColor:'yellow', //
        //fontWeight:'bold',
        //height:'10px', /* bacan */
        //paddingTop:'0', /* no funciona */
        //paddingBottom:'0', /* no funciona */
        //margin:0, /* no me funciona */
      },
    },
}

const PartecitaSubtotal=(txtCuotas,txtSubtotal,txtIva,txtTotal)=>{
return(
  <div id="divSubtotal" name="divSubtotal" style={{margin:'0%',backgroundColor:'hotpink',paddingTop:'1%', paddingBottom:"1%"}}>
    <Row style={{margin:'0',}}>
      <Col xs="3" style={{margin:'0',padding:'0',textAlign:'center',}}>
        <Label >Cuotas</Label>
        <Input disabled name="txtCuotas" id="txtCuotas" style={{width:'80%',marginLeft:'10%',textAlign:'center',}} value={txtCuotas} />
      </Col>
      <Col xs="3" style={{margin:'0',padding:'0',textAlign:'center',}}>
        <Label >Subtotal</Label>
        <Input disabled name="txtSubtotal" id="txtSubtotal" style={{width:'80%',marginLeft:'10%',textAlign:'center',}} value={txtSubtotal} />
      </Col>             
      <Col xs="3" style={{margin:'0',padding:'0',textAlign:'center'}}>
        <Label >Iva $</Label>
        <Input disabled name="txtIva" id="txtIva" style={{width:'80%',marginLeft:'10%',textAlign:'center',}} value={txtIva} />
      </Col>
      <Col xs="3" style={{margin:'0',padding:'0',textAlign:'center'}}>
        <Label style={{fontWeight:'bold'}} >TOTAL $</Label>
        <Input disabled name="txtTotal" id="txtTotal" style={{width:'80%',marginLeft:'10%',textAlign:'center',}} value={txtTotal} />
      </Col>             
    </Row>             
  </div>  
  )
}

const calcularTotal=(registrosFullState,set_txtCuotas,set_txtSubtotal,set_txtIva,set_txtTotal,GravaIvaPlan )=>{
//Esta rutina, me re-calcula los subtotales
//los parametros que empiecen con set_ realmente son funciones
let misCuotas=0 //para saber cuantas cuotas/filas hay
let miSubtotal=0 //base imponible
let miIvaP=parseFloat(valueDC.iva.Porcentaje) //iva en % (lo saco del context)
let miIvaD=0 //iva en $
let miTotal=0 //Obviamente el total del plan

//Hay que recordar en el JSON los decimales estan separado con punto (ok asi me sirve bien)
//por otro lado, no es bueno recorrer directamente del estado ya que nunca está la última version.
//lo ideal es pasarlo a otro array y hacer la suma ahi en la copia (asi es JS lamentablemente)

registrosFullState.forEach( (fila) => {
  misCuotas++
  miSubtotal +=parseFloat(fila.MontoDetalle)
})

//calculo el IVA en $
if ( GravaIvaPlan=="1"){
  miIvaD = miSubtotal*miIvaP/100
}

//Calculo el total
miTotal=miSubtotal+miIvaD

set_txtCuotas(misCuotas.toFixed(0))
set_txtSubtotal(miSubtotal.toFixed(2))
set_txtIva( miIvaD.toFixed(2) )
set_txtTotal( miTotal.toFixed(2) )
}

//por fin. Aquí no es necesario recibir como parametro la varibale row (NO SE POR QUE ? hay que averiguar )
const filaCondicionalExterna=(rowRegistroState)=>[
{
    when: (row) => (rowRegistroState!=null && row.IDcuotaDetalle==rowRegistroState.IDcuotaDetalle),
    style: (row) =>({ 
      backgroundColor:valueDC?.sistema?.coloresFilaSeleccionadaSecundaria,
      color: 'white',
     }),    
},  
]

const ModificarPlanDePagos_Detalle=(props)=>{
  const [rowRegistroState, set_rowRegistroState] = useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState] = useState('lista') //Arranco viendo obviamente la lista de cuotas. Luego el usuario puedee llamar a: crear SERIE, o eliminar o modificar una cuota
  const [registrosFullState, set_registrosFullState] = useState([]) //OBLIGATORIO EMPEZAR con []. Sirve para guardar TODOS los registros (en el use effect si recibe NULL se devueove al componente llamador)
  const [modalCrearSerie,set_modalCrearSerie]=useState(false) //para abrir el modal de crear SERIE 
  const [modalModificarCuota, set_modalModificarCuota] = useState(false) //para abrir el modal de modificar una cuota en especial
  //variables para los subtotales (son 4)
  const [txtCuotas,set_txtCuotas] = useState(null)  
  const [txtSubtotal,set_txtSubtotal] = useState(null)  
  const [txtIva,set_txtIva] = useState(null)  
  const [txtTotal,set_txtTotal] = useState(null)  

const refrescarData=async()=>{
  let data=new FormData()
  data.append('miSol','planPago_buscarPlanDePagoDetalle') //Osea las cuotas de un determinado plan
  data.append('IDplanDetalle',props.IDplan)
   
  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullState(miRecibido) 
}  

// ==============================================================
// ========= simulo el componentDidMount y ciclo de vida ========
//===============================================================
useEffect(()=>{
  //Cuando el plan sea viejo (cuando el llamador es la tabela), tengo que buscar en la BDD las cuotas detalle
  if (props.opcionRegistro_imc=="m") {
    refrescarData()
  }

  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null)
  }
  },[]
)
  // *** cuando recibo NULL de mi API, entonces me devuelvo al componente llamador
  // pero si todo está bien, entonces re-calculo los totales
useEffect(()=>{  
  if (registrosFullState==null){
    props.ocultarModal_Plan('close',null) 
  }  
  else{ 
    //hago un poderosisimo callBack. ni yo mismo entiendo qué hice
    //const {misCuotas,miSubtotal} =( () => calcularTotal(registrosFullState,set_txtCuotas ) )() //asi funciona perfecta también
    //( () => calcularTotal(registrosFullState,set_txtCuotas,set_txtSubtotal ) )() //asi tambien funciona perfecto
    calcularTotal(registrosFullState,set_txtCuotas,set_txtSubtotal,set_txtIva,set_txtTotal,props.GravaIvaPlan )
  }
  },[registrosFullState]  
)
// =====================================================
// ============== fin de ciclo de vida =================
//======================================================

const miEstructura=[
{
      name: 'IDcuotaDetalle', //idrojo de la cuota
      selector: row => row.IDcuotaDetalle,
      sortable: true,
      center: true,
      grow: 0.2,
      compact: true,
      omit: true, //oculta (siempre) la celda
},
{
      name: 'IDplanDetalle', //id rojo pero del plan
      selector: row => row.IDplanDetalle,
      sortable: true,
      center: true,
      grow: 0.2,
      compact: true,
      omit: true, //oculta (siempre) la celda
},    
{
      name: 'DESCRIPCION',
      selector: row => row.DescripcionDetalle,
      backgroundColor:'yellow',      //solo para probar, luego borra esta fila 
      sortable: false,
      compact: true,
      grow: 2, //es una medida relativa (1 es mi referencia)
},
{
      name: 'MONTO',
      selector: row => row.MontoDetalle,
      sortable: false,
      compact: true,
      right: true,
      grow: 1,
},
{
      name: 'FECHA',
      selector: row => row.FechaVenceEspanol,
      sortable: false,
      compact: true,
      center: true,
      grow: 1,
},   
// =======================================================
// ============= ACCIONES ================================
// =======================================================
{
  //ACCIONES
  name:'',
  sortable:false,
  center:true,
  compact:true,
  grow:1, //originalmente 1.6    
  //width:'20%',

  //esto funciona fino
  //cell: (row) => <div><div style={{ fontWeight: 'bold' }}>{row.IDr}</div>{row.IdPrincipal}</div>,
  //esto funciona fino
  //cell: (row) => <div style={{ fontWeight: 900 }}>{row.IDr}</div>,
  //<div style={{ display:'flex' }}>            
  cell: (row) => 
  <div style={{ display:'flex' }}>            
    {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
    {/* tambien da error al poner raised. se debe poner raised="true"  */}
    {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton Editar y Eliminar */}
    <Button id="btnEditar" name="btnEditar" style={{ marginRight:'1px',}} raised = "false" primary='false' color="info" 
      onClick={ async (e) => { 
        if (await hayInternet()==false) return
        opcionRegistro_imc='m' //'m'odificar registro (osea la cuota)
        conQueAccionDeboRegresar='save' 
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_modalModificarCuota(true)
      }}>
      <FontAwesomeIcon size='lg' color="white" icon={faPencilAlt}/>
    </Button>
    <Button id="btnEliminar" name="btnEliminar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      onClick={ async (e) => {      
        set_rowRegistroState(row)
        rowRegistroClon=row
        //Hay que validar si realmente está seguro de eliminar
        const miRegistroEnviar="Seguro desea eliminar la cuota: \n" + rowRegistroClon.DescripcionDetalle
        const miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miRegistroEnviar)
        if (miResp.isDismissed) return //alomejor se arrepintio
        conQueAccionDeboRegresar='save' //para regresar al llamador (osea la tabela de planes)
        await eliminarUnaCuota(rowRegistroClon.IDcuotaDetalle) //allá elimino de la BDD y tambien del JSON del estado   
      }}>
      <FontAwesomeIcon size='lg' color="coral" icon={faTrashAlt} />
    </Button>        
  </div>, 
},   
// ================== FIN DE ACCIONES
{
      name: 'DIA',
      selector: row => row.DiaEspanol,
      sortable: false,
      compact: true,
      center: true,
      grow: 0.8,
},      
]

const eliminarUnaCuota=async(codCuota)=>{
  //Paso 1)
  //elimino una cuota fisicamente de la BDD y luego lo elimino también del JSON del estado
  let data=new FormData()
  data.append('miSol','planPago_eliminarUnaCuotaDeUnPlan')
  data.append('codCuota',codCuota)
  //en recibido debo recibir un numero entero (el mismo numero de la cuota). no es un JSON sino un simple numero entero
  //Al no poder eliminar, entonces miRecibido será null
  let miRecibido=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  if (miRecibido != codCuota) return //Alomejor no se pudo eliminar 

  //Paso 2)
  //Ahora elimino del JSON del estado y refresco
  let datosFullCopia=registrosFullState.filter(item=>item.IDcuotaDetalle!=codCuota)
  //actualizo el estado para la tabela, y quito la franja azul
  set_registrosFullState(datosFullCopia)
  set_rowRegistroState(null)
  rowRegistroClon=null  

  //Paso 3)
  //Pongo una notificacion que se eliminó correctamente
  mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
}

const ComponentePrincipalBody=()=>{
  return (
    <div id="divMaster" name="divMaster" style={{ width: '100%', minHeight: '80vh', background: 'gray' }}>
      {/**** barra de titulo  */}
      <div id="divBarraTitulo" name="divBarraTitulo">
        <Row style={{ margin: '0' }}>
          <Col xs="10">
            <Label style={{ marginTop: '5px', fontWeight: 'bold', color: "pink" }}>{"Detalle del Plan Cod: " + props.IDplan }</Label>
          </Col>
          <Col xs="2" style={{ textAlign: 'right', padding: '0' }}>
            <Button style={{ width: '40px', height: '40px', }} id="btnCerrar" name="btnCerrar" color="danger"
              onClick={() => {
                props.ocultarModal_Plan(conQueAccionDeboRegresar)                        
              } }>
              <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
            </Button>
          </Col>
        </Row>
      </div> {/* divBarraTitulo */}

      {/* ***** TABELA ******/}
      <div id="divTabela" name="divTabela" style={{ marginTop: '2%', marginLeft: '1%', marginRight: '1%', borderStyle: "solid", borderColor: 'black', borderWidth: '2px', background: 'purple', width: '98%', marginBottom: '15px', }} >
        <DataTable
          //************ DATA TABLA PROPERTIES (basic) ***********/
          title= { 'Cuotas del Plan [' + props.NombrePlan + '].' + ' Grava Iva: ' + ( (props.GravaIvaPlan=="1") ? 'Si' : 'No' )}
          columns={miEstructura}
          data={ registrosFullState==null ? [] : registrosFullState } 
          //conditionalRowStyles={filaCondicionalInterna} //Asi también funciona pefecto
          conditionalRowStyles={filaCondicionalExterna(rowRegistroState)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
          keyField='IDcuotaDetalle' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
          //este onRowClicked, me sirve solamente para que al tocar/clickear un fila, se ponga de fondo morado u otro color                  
          onRowClicked={(row) => {
            set_rowRegistroState(row)
            rowRegistroClon = row
          } }                  
          //striped  //NO PONER, Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
          highlightOnHover //SI, poner
          //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
          noDataComponent=""
          //************ DATA TABLA PROPERTIES (pagination) ***********/
          pagination
          //paginationServer //change de default pagination to work with server pagination
          paginationPerPage={15} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
          paginationComponentOptions={paginacionOpciones}

          //************ DATA TABLA PROPERTIES (header) ***********/
          //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
          //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
          customStyles={miEstilacho} /* redefino algun estilo */ 
          />{/* del componente DataTable */}                   
      </div> {/* divTabela */}
      {PartecitaSubtotal(txtCuotas,txtSubtotal,txtIva,txtTotal)} {/* los parametros que le estoy enviando son variables de estado */}

      {/* botones de SERIE y la de RECARGAR */}
      <div id="divMenuInf" name="divMenuInf" style={{backgroundColor:'silver',paddingTop:'1%', paddingBottom:"1%"}}>
        <Row style={{margin:'0',}}>
          <Col xs="6" style={{margin:'0',padding:'0',textAlign:'center'}}>
            <Button id="btnSerie" name="btnSerie" color="primary" solid="true" style={{width:"80%", marginRight:'3%',color:'white'}} 
              onClick={async()=>{    
                set_rowRegistroState(null)
                rowRegistroClon=null                    
                if (await hayInternet()==false) return
                conQueAccionDeboRegresar='save' 
                set_modalCrearSerie(true)
              }}>AGREGAR UNA SERIE
            </Button>
          </Col>
          <Col xs="6" style={{margin:'0',padding:'0',textAlign:'center'}}>  
            <Button id="btnRefresh" name="btnRefresh" color="primary" solid="true"  style={{width:"80%",color:'white'}} 
              onClick= { async() => {
                set_rowRegistroState(null)
                rowRegistroClon=null                    
                await refrescarData()
              }}>REFRESH
            </Button>
          </Col>            
        </Row>             
      </div> {/* divMenuInf */}

      {/* ************************* MODAL PARA CREAR UNA SERIE *************** */}
      <Modal style={{ backgroundColor: 'blue', }} size={'md'} isOpen={modalCrearSerie}>
        <PedirSerieDePagos
          ocultarModal_Serie={(accion, jsonSerie) => {
            set_modalCrearSerie(false)
            //accion puede ser: close/save
            if (accion=='close') return
            //save
            if (accion=='save') {
              //muestro todas cuotas (viejas+la serie creada). Así me ahorro recargar leyendo de la BDD
              set_registrosFullState(jsonSerie)                      
            }
          } }
          NombrePlan={props.NombrePlan}
          IDplan={props.IDplan}
        />
      </Modal>

      {/* ************************* MODAL PARA MODIFICAR UNA CUOTA *************** */}
      <Modal style={{ backgroundColor: 'blue', }} size={'md'} isOpen={modalModificarCuota}>
        <ModificarUnaCuotaDeUnPlan
          ocultarModal_Modificar={(accion, jsonSerie) => {
            set_modalModificarCuota(false)
            //accion puede ser: close/save
            if (accion=='close') return
            //save
            if (accion=='save') {
              //muestro todas cuotas (la editada+las hermanas). Así me ahorro recargar leyendo de la BDD
              set_registrosFullState(jsonSerie)                      
            }
          } }
          //le mano variables que tengo en el clon   
          titulo={"CUOTA=> " + rowRegistroClon?.DescripcionDetalle} //titulo para la barra de titulo
          subTitulo={"MODIFICAR CUOTA"} //Subtitulo que se pone en la pestana del tab
          tablaQueAfecta={"planpagos_detalle"} //podria ser: planpagos_detalle / cxc_alumnos
          opcionRegistro_imc={"m"} //desde esta tabela, siempre va "m"
          codCuota={rowRegistroClon?.IDcuotaDetalle}
          tipoPM={"P"} //desde esta tabela, siempre va "P"
          monto={rowRegistroClon?.MontoDetalle}
          fecha={rowRegistroClon?.FechaVence}
          descripcion={rowRegistroClon?.DescripcionDetalle}                  
        />
      </Modal>              
    </div> //divMaster
  )
}

const ComponentePrincipal = () => {
    return (
      <AppContextConsumer>
        { (value) => {
          valueDC=value
          return ComponentePrincipalBody()
        } }
      </AppContextConsumer>
    )
  }

  //*******************************************************************
  // ***************** Programa principal *****************************
  //*******************************************************************
  if (nombreComponenteParaVerState == 'lista') return ComponentePrincipal()

} // del const ModificarPlanDePagos_Detalle

export default ModificarPlanDePagos_Detalle